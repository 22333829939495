<template>
  <Form v-if="!hasBeenSubmitted" class="relative" @submit="handleSubmit">
    <h1 class="mt-12 font-bold text-center text-3xl">
      Let’s register yourself
    </h1>
    <div class="text-center mt-3 mb-10">
      <div class="font-semi-bold">You don't have an account yet.</div>
      <p>Fill in the details below and register now.</p>
    </div>
    <TextInput
      v-model="formData.fullName"
      label="Full Name"
      placeholder="Full Name"
      :rules="[isRequired, nameMaxLength]"
      @update:model-value="errorMessage = ''"
    />
    <TextInput
      v-model="formData.preferredName"
      label="Preferred Name"
      placeholder="Preferred Name"
      :rules="[isRequired, nameMaxLength]"
      @update:model-value="errorMessage = ''"
    />
    <TextInput
      v-model="formData.email"
      label="Email"
      placeholder="Email"
      disabled
      :rules="[isRequired, isEmail]"
      @update:model-value="errorMessage = ''"
    />
    <TextInput
      v-model="formData.company"
      label="Company"
      placeholder="Company"
      :rules="[companyMaxLength]"
      @update:model-value="errorMessage = ''"
    />
    <PasswordInput
      v-model="formData.password"
      label="Password"
      placeholder="Password"
      :rules="[isRequired, passwordMinLength, passwordMaxLength]"
      @update:model-value="errorMessage = ''"
    />
    <PasswordInput
      v-model="confirmationPassword"
      label="Confirm Password"
      placeholder="Confirm Password"
      :rules="[
        isRequired,
        arePasswordsEqual,
        passwordMinLength,
        passwordMaxLength,
      ]"
      @update:model-value="errorMessage = ''"
    />

    <alert v-if="errorMessage" class="mt-4" :type="AlertType.ERROR">
      {{ errorMessage }}
    </alert>

    <Btn
      :size="BtnSize.LG"
      :variant="BtnVariant.FILLED"
      :color="BtnColor.PRIMARY"
      class="w-full mt-10"
      type="submit"
    >
      Register
    </Btn>
    <Btn
      :size="BtnSize.LG"
      class="w-full mt-5 mb-15"
      @click="emit('change-type', SelfRegisterType.SIGN_IN)"
    >
      Back to Sign in
    </Btn>
  </Form>
  <div v-else class="pt-14 pb-13">
    <h1 class="font-bold text-center text-3xl">Please check your email</h1>
    <p class="text-center font-semibold">
      We have sent you a confirmation email.
    </p>
    <Btn
      :size="BtnSize.LG"
      :variant="BtnVariant.OUTLINED"
      :color="hasBeenSubmitted ? BtnColor.PRIMARY : BtnColor.SECONDARY"
      class="w-full mt-10"
      @click="emit('change-type', SelfRegisterType.SIGN_IN)"
    >
      Back to Sign in
    </Btn>
  </div>
</template>

<script setup lang="ts">
import { definePageMeta, ref, computed, navigateTo, isEmail } from '#imports';
import { Form } from 'vee-validate';
import { TextInput } from '~/components/inputs/text-input';
import { Btn, BtnSize, BtnColor, BtnVariant } from '~/components/btn';
import { isRequired, minLength, maxLength, isEqual } from '~/utils/validators';
import { useLoader } from '~/composables/use-loader';
import { PasswordInput } from '~/components/inputs/password-input';
import { Alert, AlertType } from '~/components/alert';
import { authForbidden } from '~/middleware/auth-forbidden';
import { apiSelfRegistration, SelfRegisterType } from '~/api/auth';

definePageMeta({
  layout: 'centered',
  middleware: authForbidden,
});

const errorMessage = ref('');

type Props = {
  modelValue: string;
};
const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'change-type', value: SelfRegisterType): void;
}>();

const formData = ref({
  fullName: '',
  preferredName: '',
  company: '',
  email: props.modelValue,
  password: '',
});

const hasBeenSubmitted = ref<boolean>(false);
const confirmationPassword = ref<string>('');
const computedPassword = computed(() => formData.value.password);
const arePasswordsEqual = isEqual(computedPassword);
const passwordMaxLength = maxLength(64);
const passwordMinLength = minLength(6);
const nameMaxLength = maxLength(200);
const companyMaxLength = maxLength(200);

const handleSubmit = async () => {
  await useLoader({
    action: async () => {
      await apiSelfRegistration(formData.value);
      navigateTo({ path: '/auth/login' });
      hasBeenSubmitted.value = true;
    },
    onError: (err: any) => {
      errorMessage.value = err?.data?.message ?? 'Connection error';
    },
  });
};
</script>
